import React from 'react';
import { createContext, useContext, useState, ReactNode } from 'react';  
  
interface AuthContextValue {  
  accessToken: string | null;  
  setAccessToken: (token: string | null) => void;
  userId: string | null;
  setUserId: (userId: string) => void;
}  
  
const AuthContext = createContext<AuthContextValue | undefined>(undefined);  
  
export const useAuth = () => {  
  const context = useContext(AuthContext);  
  if (!context) {  
    throw new Error('useAuth must be used within an AuthProvider');  
  }  
  return context;  
};  
  
interface AuthProviderProps {  
  children: ReactNode;  
  token: string | null;  
}  
  
export const AuthProvider = ({ children, token }: AuthProviderProps) => {  
  const [accessToken, setAccessToken] = useState<string | null>(token);  
  const [userId, setUserId] = useState<string | null>(null);

  const value: AuthContextValue = {  
    accessToken,  
    setAccessToken,
    userId,
    setUserId, 
  };  
  
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;  
};  
