import React, { ReactElement, lazy, Suspense, useEffect} from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import SpinningComponent from '../components/spinner';

const Home = lazy(() => import('../views/home'));
const NetworkAnalysisSummary = lazy(
  () => import('../views/network-analysis-summary')
);
const NetworkGraph = lazy(() => import('../views/network-graph'));

const MainRouter: React.FC = (): ReactElement => {
  const appInsights = useAppInsightsContext();
  const navigate = useNavigate();

  useEffect(() => {
    appInsights.trackPageView({ uri: window.location.href });
  }, [appInsights, navigate]);

  return (
    <Suspense fallback={<SpinningComponent />}>
      <Routes>
        <Route path="/:userId?" element={<Home />} />
        <Route path="/network-analysis" element={<NetworkAnalysisSummary />} />
        <Route path="/network-graph" element={<NetworkGraph />} />
      </Routes>
    </Suspense>
  );
};

export default MainRouter;
