import React from 'react';
import { Stack, Spinner, Icon } from '@fluentui/react';
import { StyledLabel } from '../styled-components';

const SpinningComponent = ({ message }: any) => {
  return (
    <Stack
      horizontal
      horizontalAlign="center"
      verticalAlign="center"
      tokens={{ childrenGap: 10 }}
    >
      <Icon iconName='info' />
      {message && <StyledLabel customStyles={'font-weight: 400;'}>{message}</StyledLabel>}
      <Spinner />
    </Stack>
  );
};

export default SpinningComponent;
