import React from 'react';
import styled, { css } from 'styled-components';
import { Stack, IStackProps, Icon, Label } from '@fluentui/react';
import { getColor } from '../../utils/cssStyles';

interface StyledStackProps extends IStackProps {
  customStyles?: React.CSSProperties;
}

interface StyledGridItemProps {
    backgroundColor: string;
}

interface StyledGridProps {
  paddingLeft?: string;
}

interface StyledIconProps {
  iconPath: string;
}

interface StyledLabelProps {
  marginTop?: string;
  marginBottom?: string;
  color?: string;
  fontSize?: string;
  customStyles?: any;
}
  
const StyledIcon = styled(Icon)<StyledIconProps>`  
    display: inline-block;  
    width: 24px;  
    height: 24px;  
    background-image: ${({iconPath}) => `url(${iconPath})`};  
    background-size: cover;  
    background-repeat: no-repeat;  
    background-position: center;  
`; 

const StyledStack = styled(Stack).attrs<StyledStackProps>((props) => ({
  horizontal: true,
  horizontalAlign: 'start',
  verticalAlign: 'center',
  style: props.customStyles,
})) <StyledStackProps>`
  padding: 16px;
  gap: 8px;
  justify-content: start;
  display: flex;
`;

const StyledGridItem = styled.div<StyledGridItemProps>`
    display: flex;
    background-color: ${(props) => props.backgroundColor};
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    padding: 0.5rem;
    margin: 0.5rem;
`;

const StyledGrid = styled.div<StyledGridProps>`
  padding-left: ${(props) => props?.paddingLeft || ''};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));

  @media (min-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }

  @media (min-width: 960px) {
    grid-template-columns: repeat(auto-fill, minmax(33.33%, 1fr));
  }

  @media (min-width: 1280px) {
    grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  }
`;

const StyledLabel = styled(Label)<StyledLabelProps>`
  margin-top: ${(props) => props.marginTop || '2px'};
  margin-bottom: ${(props) => props.marginBottom || '2px'};
  color: ${(props) => props.color || getColor('common').black};
  font-size: ${(props) => props.fontSize || '14px'};
  ${(props) => props.customStyles && css(props.customStyles)};
`;

export { StyledStack, StyledGridItem, StyledGrid, StyledIcon, StyledLabel };
