import React from 'react';
import { createContext, useContext, useState, ReactNode } from 'react';  
  
interface NetworkTraceFileContextValue {  
  file: any;
  setFile: (file: any) => void;
  sourceIps: string[];
  setSourceIps: (sourceIps: any) => void;
  destinationIPsAndPorts: any;
  setDestinationIPsAndPorts: (destinationIPsAndPorts: any) => void;
  allIpData: any;
  setAllIpData: (allIpData: any) => void;
  reverseMapDomainNames: any;
  setReverseMapDomainNames: (reverseMapDomainNames: any) => void;
  progress: number;
  setProgress: (progress: number) => void;
}  
  
const NetworkTraceFileContext = createContext<NetworkTraceFileContextValue | undefined>(undefined);  
  
export const useNetworkTraceFile = () => {  
  const context = useContext(NetworkTraceFileContext);  
  if (!context) {  
    throw new Error('useNetworkTraceFile must be used within an NetworkTraceFileProvider');  
  }  
  return context;  
};
  
interface NetworkTraceFileProviderProps {  
  children: ReactNode;
}  
  
export const NetworkTraceFileProvider = ({ children }: NetworkTraceFileProviderProps) => {  
  const [destinationIPsAndPorts, setDestinationIPsAndPorts] = useState(null);
  const [allIpData, setAllIpData] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [sourceIps, setSourceIps] = useState<string[]>([]);
  const [reverseMapDomainNames, setReverseMapDomainNames] = useState<any>(null);
  const [progress, setProgress] = useState(0);

  const value: NetworkTraceFileContextValue = {  
    file,
    setFile,
    sourceIps,
    setSourceIps,
    destinationIPsAndPorts,
    setDestinationIPsAndPorts,
    allIpData,
    setAllIpData,
    reverseMapDomainNames,
    setReverseMapDomainNames,
    progress,
    setProgress,
  };  
  
  return <NetworkTraceFileContext.Provider value={value}>{children}</NetworkTraceFileContext.Provider>;  
};  
