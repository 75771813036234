import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './services/application-insights.service';
import MainRouter from './routes/main';
import ThemeProvider from './theme';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { AuthProvider } from './contexts/AuthContext';
import { NetworkTraceFileProvider } from './contexts/NetworkTraceFileContext';
import NotFound from './views/404';
import { MessageData } from './types';

initializeIcons();

const rootElement = document.getElementById('root') as HTMLElement;
rootElement.style.backgroundColor = 'white';
// const root = ReactDOM.createRoot(rootElement);

const trustedOrigins = process.env.REACT_APP_TRUSTED_ORIGINS?.split(',') ?? [];

const renderApp = (token: string) => {
  ReactDOM.render(
    <React.StrictMode>
      <AuthProvider token={token}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <NetworkTraceFileProvider>
            <ThemeProvider>
              <HelmetProvider>
                <BrowserRouter>
                  <MainRouter />
                </BrowserRouter>
              </HelmetProvider>
            </ThemeProvider>
          </NetworkTraceFileProvider>
        </AppInsightsContext.Provider>
      </AuthProvider>
    </React.StrictMode>,
    rootElement
  );
};

const getToken = (parentOrigin: string): Promise<string> => {
  return new Promise<string>((resolve) => {
    const messageListener = (event: MessageEvent<MessageData>) => {
      if (event.data.action === 'requestToken') {
        resolve(event.data.token!);
        window.removeEventListener('message', messageListener);
      }
    };
    window.addEventListener('message', messageListener);
    window.parent.postMessage({ action: 'requestToken' }, parentOrigin);
  });
};

const handleMessage = (event: MessageEvent<MessageData>) => {
  if (event.data.action === 'parentOrigin') {
    const parentOrigin = event.data.origin!;
    if (trustedOrigins.includes(parentOrigin)) {
      localStorage.setItem('parentOrigin', parentOrigin);
      window.removeEventListener('message', handleMessage);
      getToken(parentOrigin).then((token: string) => {
        if (token) {
          renderApp(token);
        }
      });
    } else {
      console.error('This app cannot be embedded in an unallowed iframe.');
    }
  }
};

if (window.top !== window.self) {
  window.addEventListener('message', handleMessage);

  window.onunload = () => {
    window.removeEventListener('message', handleMessage);
  };
} else {
  ReactDOM.render(<NotFound />, rootElement);
}

reportWebVitals();  
